import * as React from "react";
import { connect } from "react-redux";
import "./index.scss";
import logo from "../../assets/login/logo.jpg";
import iconErr from "../../assets/login/icon-err.png";
import iconEyeClose from "../../assets/login/icon-eye-close.png";
import iconEyeOpen from "../../assets/login/icon-eye-open.png";
//import iconTip from "../../assets/login/icon-pwd-tip.png";
import Axios from "axios";
import { Link } from "react-router-dom";

class Login extends React.PureComponent<{}, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      account: "",
      password: "",
      // 是否显示错误信息
      isErrAct: false,
      isErrPwd: false,
      isAgree: true,
      // 错误文案
      errActMsg: "体检卡号有误，请核对后重新输入",
      errPwdMsg: "密码错误，请核对后重新输入",
      // 是否显示密码
      isShowPwd: false,
    };
  }
  componentDidMount() {
    document.title = "登录";
  }
  render() {
    const {
      account,
      password,
      isErrAct,
      isErrPwd,
      errActMsg,
      errPwdMsg,
      isShowPwd,
      isAgree,
    } = this.state;
    return (
      <div className="login-container">
        {/* logo */}
        <div className="logo-wrap">
          <img src={logo} alt="" className="logo" />
        </div>

        <form className="login-form">
          <div className={`form-item ${isErrAct ? "err" : ""}`}>
            <div>
              <div className="title">体检卡号</div>
              <input
                className="form-ipt"
                type="text"
                value={account}
                placeholder="请输入体检卡号"
                onChange={this.accountChange.bind(this)}
              />
            </div>
            {/* 错误提示 */}
            <div
              className="err-msg"
              style={{ display: isErrAct ? "block" : "none" }}
            >
              <img src={iconErr} alt="" className="icon-err" />
              {errActMsg}
            </div>
          </div>
          <div className={`form-item ${isErrPwd ? "err" : ""}`}>
            <div>
              <div className="title">密码</div>
              <div className="login-pwd-ipt">
                <input
                  className="form-ipt"
                  type={isShowPwd ? "text" : "password"}
                  value={password}
                  placeholder="请输入密码"
                  onChange={this.passwordChange.bind(this)}
                />
                <img
                  src={isShowPwd ? iconEyeOpen : iconEyeClose}
                  alt=""
                  className="icon-eye"
                  onClick={this.showPwd.bind(this)}
                />
              </div>
            </div>
            {/* 错误提示 */}
            <div
              className="err-msg"
              style={{ display: isErrPwd ? "block" : "none" }}
            >
              <img src={iconErr} alt="" className="icon-err" />
              {errPwdMsg}
            </div>
            {/* 密码输入提示 */}
            {/*<div className="pwd-input-tip" style={{ display: isErrPwd ? "none" : "block" }}>*/}
              {/*<img src={iconTip} alt="" className="icon-pwd-tip" />*/}
              {/*<span>密码为卡号后6位</span>*/}
            {/*</div>*/}

          </div>
          <div className="exborder" onClick={this.changeAgree.bind(this)}>
            <div className={` ${isAgree ? "inborder" : ""}`}></div>
          </div>
          <span className="agree">同意</span>
          <Link to="/agree">《客户同意授权书》</Link>
          <div className="form-item">
            <button
              type="button"
              className={`login-btn ${
                account.length && password.length && isAgree ? "active" : ""
              }`}
              onClick={this.login.bind(this)}
            >
              登录
            </button>
          </div>
          <div>
          温馨提示：<br/>目前正值体检高峰期，如您预约的体检中心排期已满，您可更换分院或尝试不同时间段刷新排期，您还可拨打客服电话
          <a href="tel:4006687598"> 400-668-7598 </a>进行线下人工客服协助预约。
          </div>
        </form>
      </div>
    );
  }

  accountChange(e: any) {
    this.setState({
      account: e.target.value,
      isErrAct: false,
      errActMsg: "",
    });
  }

  passwordChange(e: any) {
    this.setState({
      password: e.target.value,
      isErrPwd: false,
      errPwdMsg: "",
    });
  }

  showPwd() {
    this.setState({
      isShowPwd: !this.state.isShowPwd,
    });
  }

  //isAgree
  changeAgree() {
    this.setState({
      isAgree: !this.state.isAgree,
    });
  }

  login() {
    const { account, password, isAgree } = this.state;
    if (!account || !password || !isAgree) return;
    this.setState({
      isErrAct: false,
      errActMsg: "",
      isErrPwd: false,
      errPwdMsg: "",
    });
      Axios.post("https://api.pimh.dashuyy.net/api/Login/KeyCard", {
      cardKey: account,
      cardPassword: password,
    }).then((res) => {
      if (res.data.state === 1) {
        window.location.href = res.data.date;
      } else {
        if (res.data.errorMsg.indexOf("密码") >= 0) {
          this.setState({
            isErrPwd: true,
            errPwdMsg: "密码输入有误，请核对后重新输入",
          });
        } else {
          this.setState({
            isErrAct: true,
            errActMsg: "体检卡号有误，请核对后重新输入",
          });
        }
      }
    });
  }
}

export default connect()(Login);
