import * as React from "react";
import { connect } from "react-redux";
import ServiceTitle from "./components/serviceTitle";
import IconBook from '../../assets/service/icon-book.png'
import IconChart from '../../assets/service/icon-chart.png'
import IconDrug from '../../assets/service/icon-drug.png'
import IconHeart from '../../assets/service/icon-heart.png'
import IconMeeting from '../../assets/service/icon-meeting.png'
import IconPhoto from '../../assets/service/icon-photo.png'
import IconServiceLeft from '../../assets/service/icon-service-left.png'
import IconServiceRight from '../../assets/service/icon-service-right.png'
import IconServiceObject from '../../assets/service/icon-service-object.png'

import {baseUrl} from '../../config/url'
import "./service.scss";

interface params {
    value: any
}

class Service extends React.PureComponent<params, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            code: props.value
        }
    }
    componentDidMount() {
        document.title = '服务权益';
        let getData: any = this.props
        let params = getData.location.search.split('?insuredIdCipher=')[1]
        this.setState({
            code:params
        })

    }
    //去护理的方法
    toNursing = () => {
        window.location.href = baseUrl.nursingUrl + '/?code=' + this.state.code
    }

    //去慢药的方法
    toSlowDrug = () => {
        window.location.href = baseUrl.slowDrug + '?from=shzs&code=' + this.state.code + '&redirect_url=https%3A%2F%2Fyyb-m.test.laobaiyy.com%2Fcard%2Fhome%3Fduid%3D8ac6859278171d870178172532ff0009%26dtype%3D1'
    }

    render() {
        return (
            <div className="service-page">
                {/*头部*/}
                <div className="service-head">

                    {/*service-left*/}
                    {/*<div className="service-sale">*/}
                    {/*    <div className="service-head-icon">*/}
                    {/*    </div>*/}
                    {/*    <div className="service-head-text">护理服务</div>*/}
                    {/*    <div className="service-guide-icon"></div>*/}
                    {/*</div>*/}

                    <img onClick={this.toNursing} src={IconServiceLeft} className="service-sale" alt="">
                    </img>

                    {/*service-right*/}
                    {/*<div className="service-slow-drugs">*/}
                    {/*    <div className="service-head-icon">*/}
                    {/*    </div>*/}
                    {/*    <div className="service-head-text">慢药服务</div>*/}
                    {/*    <div className="service-guide-icon"></div>*/}
                    {/*</div>*/}

                    <img onClick={this.toSlowDrug}  src={IconServiceRight} className="service-slow-drugs" alt="">
                    </img>


                </div>
                <div className="service-object">
                    <img src={IconServiceObject} className="list-icon" alt=""/>
                    <div className="list-container">
                        <div className="list-title">
                            服务对象
                        </div>
                        <div className="list-container-text">
                            罹患重疾且确认可理赔的被保险人
                        </div>
                    </div>
                </div>
                {/*内容*/}
                {/*服务权益*/}
                <div className="service-container">
                    <ServiceTitle value="服务权益"></ServiceTitle>
                    <div className="container-detail-list">
                        <img src={IconHeart} className="detail-list-icon" alt=""/>
                        <div className="detail-list-text-one">
                            康护计划及相关康护报告
                        </div>
                    </div>


                    <div className="container-detail-list">
                        <img src={IconBook} className="detail-list-icon" alt=""/>
                        <div className="detail-list-text-one">
                            术后上门一小时居家康护指导
                        </div>
                    </div>

                    <div className="container-detail-list">
                        <img src={IconPhoto} className="detail-list-icon" alt=""/>
                        <div className="detail-list-text-one">
                            视频在线康护指导师咨询
                        </div>
                    </div>

                    <div className="container-detail-list">
                        <img src={IconChart} className="detail-list-icon" alt=""/>
                        <div className="detail-list-text-two">
                            连续6个月内定期线上回访并收取客户反馈
                        </div>
                    </div>

                    <div className="container-detail-list">
                        <img src={IconMeeting} className="detail-list-icon" alt=""/>
                        <div className="detail-list-text-two">
                            根据被保人实际需求可提供康护师线下收取保险理赔材料
                        </div>
                    </div>

                    <div className="container-detail-list">
                        <img src={IconDrug} className="detail-list-icon" alt=""/>
                        <div className="detail-list-text-two">
                            慢病购药服务（常见痛风、高血压、高血糖等慢病药）
                        </div>
                    </div>

                </div>
                {/*服务流程*/}
                <div className="service-container">
                    <ServiceTitle value="服务流程"></ServiceTitle>
                    <div className="service-steps-box">
                        <div className="service-steps">
                            <div className="service-step-number">
                                1
                            </div>
                            <div className="service-step-text">
                                从太妙商城跳转至护理小程序
                            </div>
                        </div>
                        <div className="service-steps">
                            <div className="service-step-number">
                                2
                            </div>
                            <div className="service-step-text">
                                用户上传<span className="font-bold">病理报告</span>和（<span className="font-bold">入院单</span>或者<span className="font-bold">出院小结</span>任意其中一项）
                            </div>
                        </div>
                        <div className="service-steps">
                            <div className="service-step-number">
                                3
                            </div>
                            <div className="service-step-text">
                                审核通过后，用户申请线上预约上门时间、地址等相关信息——预约完成后（4小时内）系统将推送小程序通知给客户，点击可查看上门服务信息
                            </div>
                        </div>
                        <div className="service-steps">
                            <div className="service-step-number">
                                4
                            </div>
                            <div className="service-step-text">
                                康护师上门指导服务
                            </div>
                        </div>
                        <div className="service-last-steps">
                            <div className="service-step-number">
                                5
                            </div>
                            <div className="service-step-text">
                                服务完成后，用户线上确认服务完成并做服务评价
                            </div>
                        </div>
                    </div>
                </div>
                {/*服务须知*/}
                <div className="service-container">
                    <ServiceTitle value="服务须知"></ServiceTitle>
                    <div className="notice-list">
                        <div className="notice-list-title">
                            <div className="notice-list-dot">
                            </div>
                            服务时间：
                        </div>
                        <div className="notice-list-test">
                            <div>1.客服在线时间：8:00-20:00</div>
                            <div>2.康护师可上门指导服务时间：9:00-18:00</div>
                        </div>
                    </div>

                    <div className="notice-list">
                        <div className="notice-list-title">
                            <div className="notice-list-dot">
                            </div>
                            康护师上门前致电客户时间：
                        </div>
                        <div className="notice-list-test">
                            <div>1. 预约上午9:00-12:00上门服务，提前一天致电客户，确认上门时间</div>
                            <div>2.预约下午14:00-18:00上门服务，当天上午致电客户，确认上门时间</div>
                        </div>
                    </div>

                    <div className="notice-list">
                        <div className="notice-list-title">
                            <div className="notice-list-dot">
                            </div>
                            用户可提前24小时电话取消或更改预约，否则将默认使用康护指导服务
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(Service);
