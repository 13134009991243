import * as React from "react";
import { connect } from "react-redux";
import "./index.scss";

class RegisterAgr extends React.PureComponent<{}, any> {
    componentDidMount() {
        document.title = '注册协议';
    }
    render() {

        return (
            <div className="agreement">
                <div className="title">注册协议</div>

最新版本生效日期：2020年7月1日

<p className="littletitle">一、特别提示</p>

<p>1.   欢迎您与太保妙健康平台（详见“定义”条款）的经营者共同签署本《注册协议》（下称“本协议”）并使用太保妙健康平台服务。
</p>
<p>
2.   各服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。
</p>
<p>
3.   【审慎阅读】您在点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可向太保妙健康平台客服咨询。
</p>
<p>
4.   【签约动作】当您第一次进入太保妙健康平台并点击同意本协议后，或您以其他本平台允许的方式实际使用我们的产品与/或服务，即表示您已经完全理解并同意本协议。
</p>
<p>
5.   【未成年人保护】太保妙健康平台仅同意年满18周岁且具有完全民事行为能力的成年人成为我们的用户（未满18周岁的未成年人需通过其监护人使用我们的产品与/或服务）。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应取得您的家长或法定监护人的同意并由他们创建账户，您在取得他们同意并在他们的指导下使用该账户获得我们的产品与/或服务。我们将严格按照国家相关法律法规的规定保护未成年人的个人信息。
</p>

<p className="littletitle">二、定义</p>
<p>
1.   【太保妙健康平台】：指太平洋医疗健康管理有限公司享有使用权的太保妙健康微信公众号及该微信公众号上接入的、由太平洋医疗健康管理有限公司注册与/或开发与/或运营的“太保妙健康”小程序、太妙福利联盟小程序、太妙法宝小程序以及相关的网页。本协议中，上述微信公众号、小程序、网页等被合称为“平台”或“太保妙健康平台”，或单称为“太保妙健康微信公众号”“太保妙健康小程序”、“太妙福利联盟小程序”、“太妙法宝小程序”或其他与太保妙健康平台提供的服务相关的网页简称。
</p>
<p>
2.   【经营者】：指太平洋医疗健康管理有限公司，本协议中将经营者成为“我们”。
</p>
<p>
3.   【运营者】：指太平洋医疗健康管理有限公司或太平洋医疗健康管理有限公司委托的第三方。
</p>
<p>
4.   【服务提供方】：为了更好的让用户享受更多元化的服务，我们严选各类服务供应商、整合各类具备优势资源的第三方服务提供方，为用户提供服务。太保妙健康平台包含两类服务提供方：1）太保妙健康的供应商，即根据太保妙健康平台要求为用户提供服务的服务提供方，本协议中被称为“供应商”；2）用户通过太保妙健康平台可以到达的第三方服务提供方，太保妙健康仅提供通道，用户可自行选择是否注册、接受该第三方服务提供方的服务，用户选择注册、接受后直接向该第三方付费，由该第三方服务提供方直接向用户提供服务，本协议中被称为“第三方服务提供方”。
</p>
<p>
5.   【服务规则】：指所有在太保妙健康平台上已经发布及后续发布、变更的各类规则、解读、实施细则、流程说明、公告等内容；以及太保妙健康平台提供与/或展示的具体产品与/或服务页面进行的介绍、说明等。
</p>
<p className="littletitle">三、协议范围</p>
<p>
1.   【协议组成】本协议内容由本协议正文、《隐私保护政策》、《客户信息授权书》、服务规则以及太保妙健康平台提供产品与/或服务页面共同组成。为了您更好地了解和使用我们的产品与/或服务，您需要认真阅读并遵守本协议正文、《隐私保护政策》、《客户信息授权书》、服务规则以及我们提供产品与/或服务页面进行的介绍、说明。
</p>
<p>
2.   【协议更新与补充】由于平台提供的服务会随着市场进行增加或更新，且互联网高速发展也对平台提出了不断更新的要求，您与平台签署的本协议列明的条款并不能完整罗列并覆盖您与平台的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。我们有权根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议，变更后的内容，我们将在平台上公布，也会在变更后您第一次登陆时以页面弹窗的形式进行提醒。经修订的内容一经在平台上发布，即成为本协议的一部分并自发布时生效。若您不同意任何变更的内容，应立即停止使用我们提供的产品与/或服务；若您有任何疑问，也应暂时停止使用我们提供的产品与/或服务，并咨询客服。若您继续登录或使用我们的产品与/或服务，将视为您已经接受经修订的协议。
</p>
<p>
3.   【主体信息调整】本协议项下，运营者、服务提供方可能根据平台的业务调整而发生变更，上述调整和变更不会影响您本协议项下的权益。如您在上述变更后继续使用平台服务，即视为您同意该等变更。
</p>

<p className="littletitle">四、账户注册、使用及管理</p>
<p>
1.   【用户资格】您确认，在您开始注册程序、使用平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
</p>
<p>
2.   【注册信息的提供】您使用我们的产品与/或服务时，应准确填写完成服务所必需的电子邮件地址、联系电话、联系地址、邮政编码等联系方式。若通过这些联系方式无法与您取得联系，导致您在使用服务过程中产生任何损失或增加任何费用的，将由您完全独自承担。您了解并同意，您有义务保持你提供的联系方式的有效性，如有变更或需要更新的，您应在合理时间内（即在您决定进行下一次交易之前）变更。
</p>
<p>
3.   【注册信息的真实、合法】我们默认您账号所绑定的电话号码或电子邮箱、收件地址等个人信息为真实、合法、有效。您设置的账户名称及填写的注册信息应真实合法，不违反任何法律法规及监管机构的规章，不侵害任何第三方的利益，不违反任何社会公序良俗。
</p>
<p>
4.   【信息更新】您的注册信息若发生任何变化，您应第一时间进行账号信息的相应变更或联系我们的客服进行信息变更，我们亦有权依据服务规则进行信息真实的验证。您怠于履行上述义务发生的一切后果将由您本人独自承担。
</p>
<p>
5.   【账户使用】您同意您注册的账号不会以任何形式转让、出租给第三人或授权其他人使用。如您违反本条的约定，我们可以视情况拒绝提供我们的产品与/或服务或者终止本协议。
</p>
<p>
6.   【账户使用及密码保管】您有权使用您设置的账户名、手机号及密码登录太保妙健康平台。您的个人账户及密码由您自行设置并保管，我们任何时候都不会要求您提供您的密码，您使用我们的产品与/或服务均通过您的个人账户，因此您也需为您个人账户下的一切行为负责。我们建议您妥善保管个人账户密码，在使用账户实施的所有行为均遵守国家相关法律、法规的规定和要求，不违背社会公序良俗，不损害他人的合法权益，不违反本协议及平台有关规则。我们不承担因账户密码泄露而产生的任何后果，您应通过司法、行政等救济途径向行为人追偿。
</p>
<p>
7.   【账户安全】如发现任何人未经授权使用您的登录名和密码，或发生违反本协议或平台服务规则的任何其他情况，您应立即通知我们，并首先通过您绑定的联系方式避免或阻止侵害后果的形成或扩大；您应协助我们尽可能在不超过【2】个工作日的合理时间内响应您的请求。
</p>
<p>
8.   【账户行为责任自负】您因本人操作失误或任何第三人行为而遭受的任何损失，或受到任何第三人的索赔，或受到任何政府部门的处罚，除非我们存在法定过错，否则我们不应承担任何责任。因上述情形导致我们受到损失的，您应当承担赔偿责任并承担因此发生的费用，包括合理的公证费用、律师费用。除平台存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。
</p>
<p className="littletitle">五、知识产权条款</p>
<p>
1.   我们的平台及我们提供的产品与/或服务的内容，包括但不限于文字、图片、档案、资讯、资料、网站架构、网站素材、网页设计等，均由我们、我们的关联公司或我们的合作方依法拥有知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
</p>
<p>
2.   非经我们或权利所有人的书面同意，任何人无权擅自使用、修改、反向编译、复制、公开传播、改变、散布、发行或公开发表我们平台的任何内容。若违反前述规定，您将承担损害赔偿责任。
</p>

<p className="littletitle">六、产品/服务条款</p>
<p>
1.   【概述】太保妙健康平台为您提供综合性的健康服务解决方案以及相关的商品，为了让您更好的使用我们的产品/服务，请您仔细阅读以下提示：
</p>
<p>
(1) 本协议项下的产品/服务是否收费、如何收费、服务规则、服务限制等，根据您购买的产品/服务种类有所不同，您完成注册后，太保妙健康平台会根据您提供的信息为您自动匹配相关的权限，您注册成功后可在太保妙健康平台验证您的相关权限信息是否匹配。
</p>
<p>
(2) 本协议项下，平台及供应商提供的为健康管理相关的咨询服务，该咨询服务为针对特定用户提供的症状描述等作出的单次咨询，不具有普适性；受限于技术条件限制，该类健康管理咨询服务仍具有一定的局限性，平台或供应商提供的咨询服务、以及因咨询服务提供的咨询报告、建议等，并非具有诊断性质的诊断书，不能作为用户采取治疗方案的直接依据，用户如有身体不适的症状，仍应及时就医。
</p>
<p>
(3) 平台方有权根据其系统配置，调整包括但不限于接口、服务名称、模块名称等内容，但该类调整不会影响用户使用相关自付费的服务。
</p>
<p>
(4) 付费项目在用户权限到期后会自动关闭，如用户需继续使用，可根据页面提示进行购买；如用户不再继续使用付费项目，用户仍可使用平台上的其他免费服务。
</p>
<p>
2.   【服务介绍】
</p>
<p>
(1) 本协议项下服务包括健康互动任务、太妙法宝、健康管家培训、健康关爱服务、视频医生、原研药优惠、监测报告、太妙福利联盟、商城，具体服务内容、服务规则、服务限制等信息，以产品与/或服务页面载明的为准。
</p>
<p className="littletitle">七、责任限制</p>
<p>
1.   【不可抗力及第三方原因】我们的平台依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，我们将不承担任何的违约责任：
</p>
<p>
(1) 因自然灾害、突发卫生公共事件、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；
</p>
<p>
(2) 因电力供应故障、通讯网络故障等公共服务因素或任何第三方因素；
</p>
<p>
(3) 在我们已尽善意管理的情况下，因常规或紧急的设备与系统检测、维护、升级，设备与系统故障、网络信息与数据安全等因素，导致网络服务在合理时间内的中断，我们将会提前在平台公告通知但无需承担责任。
</p>
<p>
2.   【责任限制】若发生以下情形，我们有权采取相应措施而不承担任何责任：
</p>
<p>
(1) 若依据相关法律法规，我们有合理的理由认为用户的信息数据、交易行为、以及有关的其他事项存在不符合相关法律法规的情形，我们将有权对用户数据进行行为是否涉嫌违法或不当的审查而不承担任何责任；
</p>
<p>
(2) 我们授权的第三方或您与我们一致同意授权的第三方与您发生争议的，我们不承担相应责任；
</p>
<p>
(3) 除非我们存在故意或者重大过失，否则您不应向我们追究责任，而应当自行向相关行为人追偿。
</p>

<p className="littletitle">八、违约处理措施</p>

<p>
1.   发生如下情形之一的，视为您违约：
</p>
<p>
(1) 使用平台服务时违反有关法律法规规定的；
</p>
<p>
(2) 违反本协议账户管理、知识产权的相关约定的。
</p>
<p>
(3) 若我们认定您违约，我们将有权采取以下处理措施：
</p>
<p>
(4) 根据相应规则立即对您发表的评论等信息进行删除、屏蔽处理；
</p>
<p>
(5) 您在平台上实施的行为若对我们或其他用户产生不良影响，我们可暂停您的账户，或中止甚至终止向您提供服务。
</p>

<p className="littletitle">九、损失赔偿条款</p>
<p>
1.   如您的行为使我们及/或关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿我们及/或关联公司的上述全部损失。
</p>
<p>
2.   如您的行为使我们及/或关联公司遭受第三人主张权利的，我们及/或关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。
</p>
<p className="littletitle">十、反商业贿赂条款</p>
<p>
1.   如您向我们的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。发生上述情形的，我们可立即终止与您的协议，并向您索赔违约金及/或赔偿金，该等金额以我们因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。
</p>
<p className="littletitle">十一、通知送达</p>
<p>
1.   【有效联系方式】您成为我们的注册用户时应提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。
</p>
<p>
2.   【通知方式】我们将向您注册时提供的联系方式的其中之一或其中若干联系方式送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的商品广告信息、促销优惠等商业性信息；您如果不愿意接收此类信息，您有权通过平台提供的相应的退订功能进行退订。
</p>
<p>
3.   【通知的送达】我们通过您注册时提供的联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于向您提供的联系电话发送手机短信、向您提供的电子邮件地址发送电子邮件，在发送成功后即视为送达；我们以纸质载体发出的书面通知，按照您提供的联系地址交邮后的第三个自然日即视为送达。
</p>
<p>
4.   【司法送达地址】您同意司法机关（包括但不限于人民法院）可以通过向您注册时提供的联系方式发送手机短信、电子邮件或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱为您在我们平台注册、更新时提供的手机号码、电子邮箱，司法机关向您上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您身份证上的地址或您在我们平台注册或购物时提供的有效联系地址。您同意司法机关可采取以上一种或多种送达方式向您送达法律文书，不论您是否签收或回应均视为有效送达。司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。您应当保证所提供的联系方式是准确、有效的，若发生变化应进行实时更新。如果因您提供的联系方式不真实准确，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。
</p>
<p className="littletitle">十二、协议终止</p>
<p>
1.   您有权通过以下任一方式终止本协议：
</p>
<p>
(1) 您可通过联系太保妙健康平台客服主动注销账户而终止本协议；
</p>
<p>
(2) 本协议发生变更，您明示不愿接受变更并停止使用的；
</p>
<p>

(3) 您明示不愿提供并主动或要求删除实现业务功能所必需的必要信息，导致该项业务功能客观不能实现，实质上终止本协议的。
</p>
<p>
2.   出现以下情况时，我们可以按您账户联系方式通知您终止本协议：
</p>
<p>
(1) 您违反本协议约定，我们依据违约条款终止本协议的；
</p>
<p>
(2) 您提供的电话号码或电子邮箱不存在或无法接收验证码，没有其他方式可以与您进行联系；或平台以其它联系方式通知您更改电话号码或电子邮箱，而在通知发送后三个工作日内您未见响应的；
</p>
<p>
(3) 您违反本协议或平台的规则，我们认为达到严重程度，影响我们的商誉或平台其他用户的利益；
</p>
<p>
(4) 其它我们认为应当终止服务的情况。
</p>
<p>
3.   本协议终止后，除法律有明确规定外，我们将无义务向您或您指定的第三方披露您账户中的任何信息。
</p>
<p>
4.   本协议终止后，您可以申请注销太保妙健康账户，我们将在您提出申请后完成对您的账户注销审核，审核通过后，我们将按规定对您的个人信息进行删除或匿名化处理。
</p>
<p>
5.   本协议终止后，对于您在本协议存续期间产生的交易订单，我们将视订单处理的进程单方决定是否关闭该等交易订单；如交易订单的履行已无法拦截，则您应当就该交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。
</p>
<p>
6.   本协议终止后，我们仍享有以下权利：
</p>
<p>
(1) 继续保留您留存于太保妙健康平台的交易信息、浏览访问记录等信息；
</p>
<p>
(2) 对于您过往的违约行为，我们仍可根据本协议向您追究违约责任。本条款具有独立法律效力，不受本协议的终止而影响。
</p>
<p>
十三、法律管辖和适用
</p>
<p>
1.   【法律适用】本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
</p>
<p>
2.   【管辖】由本协议引起的任何争议，双方尽量友好协商解决；若不能协商解决的，任何一方均可诉至上海市浦东新区人民法院。
</p>
<p>
十四、协议生效日期
</p>
<p>
我们在太保妙健康平台发布本协议，本协议自发布之日起生效。

</p>

            </div>
        );
    }
}

export default connect()(RegisterAgr);
