import * as React from "react";
import {connect} from "react-redux";
import './empower.scss'

class Informed extends React.PureComponent<{}, any> {
    componentDidMount() {
        document.title = '用户知情确认及授权书';
    }

    render() {

        return (
            <div>
                <p className="informed-person">授权人：当前提交人</p>
                <p className="informed-info"> 本人同意太平洋医疗健康管理有限公司从科盎健康体检预约平台的存储数据中取得或直接收集本人的相关信息（包括但不限于姓名、性别、婚姻状态、身份证号码、手机号码）及本人填写、提交的其他相关信息用于为本人提供健康体检服务，并同意科盎健康有权向第三方体检机构（美年大健康、瑞慈、慈铭）提供本人的上述信息用于为本人提供健康体检服务。
                </p>
                <p className="informed-info">本人确认，本入口项下接入的服务是由科盎健康为本人提供，由科盎健康承担责任，体检服务由美年大健康、瑞慈、慈铭等体检机构承担责任，与太平洋医疗健康管理有限公司无关。</p>
            </div>


        );
    }
}

export default connect()(Informed);
