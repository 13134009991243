import * as React from "react";
import { connect } from "react-redux";
import "./index.scss";
import { Link } from 'react-router-dom';

class Login extends React.PureComponent<{}, any> {
    componentDidMount() {
        document.title = '信息授权书';
    }
    render() {

        return (
            <div className="agreement">

                <div className="title">信息授权书</div>

                尊敬的用户：

                <p>非常感谢您使用<span className="company">太平洋医疗健康管理有限公司</span>（以下简称“我们”）的产品和服务，为让您更好地使用我们提供的产品和服务，请您务必审慎阅读、充分理解本授权书、<Link to="/register">《注册协议》</Link>、<Link to="/privacy">《隐私保护政策》</Link>各条款内容。<span className="sentence">如您是未成年人，则需要您的监护人同意您使用我们提供的产品和服务、同意相关服务条款。</span></p>
                <p>

                    为了保障您的权益，除非您已阅读并接受本授权书所有条款，否则您无权使用我们所提供相关产品和服务。您的使用、登陆等行为即视为您已阅读并同意本授权书的约束。如果您对本授权书的相关内容有疑问、意见或建议，您可通过客服热线【400 880 8660】与我们联系。</p>
                <p>

                    您知悉并同意，为方便您使用我们的产品和服务，我们将按照本授权书和《隐私保护政策》收集、储存、使用您的相关信息，包括但不限于您的姓名、性别、健康管理、配送地址、联系方式、通讯录、定位信息等。当我们将您的个人信息用于本授权书及《隐私保护政策》未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，我们会通过您主动做出勾选或其他形式事先征求您的同意。</p>
                <p>

                    此外，我们将严格按照本授权书和《隐私保护政策》对相关信息采取专业加密存储与传输方式，采取合理措施保障您的信息安全。</p>
                <p>

                    本授权书的订立、执行、解释及争议的解决均应适用中国法律。如双方就本授权书发生任何争议，双方应尽力友好协商解决；协商不成时，应向授权书签订地有管辖权的人民法院提起诉讼。</p>
                <p>

                    如果本授权书中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</p>
                <p>

                    您点击“同意”按钮即视为您完全接受本授权书，在点击之前请您再次确认已知悉并完全理解本授权书的全部内容。</p>


                <p>本授权书签订地为中华人民共和国上海市浦东新区。</p>
            </div>
        );
    }
}

export default connect()(Login);
