import * as React from "react";
import { Switch, Route } from "react-router";
import Agreement from "./components/login/Agreement";
import Login from "./components/login/Login";
import Privacy from "./components/login/Privacy";
import registerAgr from "./components/login/registerAgr";
import "../src/custom.css";
import Empower from "./components/empower/Empower";
import Informed from "./components/empower/Informed";
import Service from "./components/service/Service";

export default () => (
  <Switch>
    <Route exact path="/" component={Empower} />
    <Route exact path="/Informed" component={Informed} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/agree" component={Agreement} />
    <Route exact path="/register" component={registerAgr} />
    <Route exact path="/privacy" component={Privacy} />
    <Route exact path="/service" component={Service} />
  </Switch>
);
