import * as React from "react";
import { connect } from "react-redux";
import "./index.scss";

class Privacy extends React.PureComponent<{}, any> {
    componentDidMount() {
        document.title = '隐私保护政策';
    }
    render() {

        return (
            <div className="agreement">

                <div className="title">隐私政策</div>


                <p>【特别提示】请您仔细阅读本政策，特别是加粗划线的内容，并确认我们对您个人信息的处理规则。阅读过程中，如果您有任何疑问，可联系我们客服咨询，如您不同意政策中的任何条款，您应立即停止使用本平台。
    </p>
                <p>

                    如我们及关联公司（范围详见定义部分）的服务或产品中使用了太保妙健康或/和太妙福利联盟提供的服务或产品（例如直接使用太保妙健康或/和太妙福利联盟帐号登录）但未设独立隐私权政策的，则本政策同样适用于该部分服务或产品。我们及关联公司就其向您提供的产品或服务单独设立隐私权政策的，则相应产品或服务适用该相应的隐私政策。
</p>
                <p>

                    如您对本隐私政策或相关事宜有任何问题，您可随时通过访问我们的在线客服或拨打我们的客服电话021-33963797等多种方式与我们联系。
</p>
                <p>
                    政策更新时间：2020.7.1
</p>
                <p>
                    政策生效时间：2020.7.1
</p>
                <p>
                    第一部分 定义
</p>
                <p>
                    【太保妙健康】是指，太平洋医疗健康管理有限公司享有使用权的太保妙健康微信公众号及该微信公众号上接入的、由太平洋医疗健康管理有限公司注册与/或开发与/或运营的“太保妙健康”小程序、太妙福利联盟小程序、太妙法宝小程序、商城小程序以及健康管家培训网页、居家防疫网页、AI智能筛查网页等网页。本协议中，上述微信公众号、小程序、网页等以及太平洋医疗健康管理有限公司被合称为“平台”，“我们”或“太保妙健康”。
</p>
                <p>
                    【关联公司】是指，指在中国境内与太平洋医疗健康管理有限公司构成会计准则层面关联方的公司。
</p>
                <p>
                    【用户】是指，太保妙健康的使用人，在本政策中被称为“您”或“用户”。
</p>
                <p>
                    【个人信息】是指，以电子或者其他方式记录的，能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
</p>
                <p>
                    【个人敏感信息】是指，一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等，包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童的个人信息等（我们将在本隐私权政策中对具体个人敏感信息以粗体进行显著标识）。
</p>
                <p>
                    【运动信息】是指，以各种方式记录、收集的运动、步数、距离、卡路里等。
</p>
                <p>
                    【推广会员】是指，根据《注册协议》、《营销服务协议》、本政策、相关平台规则及产品/服务页面完成在线注册、提供推广服务并获取收入的，具备完全民事行为能力的自然人、法人或其他组织。
</p>
                <p>
                    第二部分 隐私保护条款
</p>
                <p>
                    本隐私保护条款将帮助您了解以下内容：
</p>
                <p>
                    一、我们需要收集您的哪些个人信息及如何使用
</p>
                <p>
                    二、我们如何使用Cookie和同类技术
</p>
                <p>
                    三、我们如何共享、转让、公开披露您的个人信息
</p>
                <p>
                    四、我们如何保护您的个人信息
</p>
                <p>
                    五、您如何管理您的个人信息
</p>
                <p>
                    六、我们如何保护未成年人信息
</p>
                <p>
                    七、您的个人信息如何在全球范围转移
</p>
                <p>
                    八、本政策如何更新及告知您
</p>
                <p>
                    九、您如何联系我们
</p>
                <p>
                    本条款主要向您介绍您在使用我们的产品与/或服务时的隐私保护政策（以下简称“本政策”），您可以通过本部分内容了解我们需要收集您的哪些个人信息，以及如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
</p>
                <p>
                    请您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本政策所写明的内容，以便您可以按照本隐私政策的指引做出您认为适当的选择。
</p>
                <p>
                    一、我们需要收集您的哪些个人信息及如何使用
</p>
                <p>
                    在您使用我们的产品与/或服务时，我们需要／可能需要您提供的个人信息包括以下两种：
</p>
                <p>
                    （一）当您选择使用我们服务的【基本业务功能】时，需要向我们提供或允许我们收集【实现基本功能的必要】个人信息，如您拒绝提供相应信息，您将无法正常使用我们的服务。
</p>
                <p>
                    （二）若您为了更好的服务选择使用我们服务的【改善功能】，您可选择授权我们收集、使用除实现基本功能以外的其他个人信息。如您拒绝提供，您将无法正常使用相关改善功能或无法达到我们拟达到的功能效果。
</p>
                <p>
                    不同的业务功能所需要的个人信息类型、范围有所区别，具体所需信息请以使用该项功能时的提示为准，我们的服务所需信息包括但不限于以下，请审慎阅读：
</p>
                <p>
                    1、成为我们的用户
</p>
                <p>
                    【基本功能】：您注册成为太保妙健康用户，需要您至少以主动填写或授权的方式向我们提供您准备使用的【您本人的手机号码】来验证您的身份是否有效。如果您仅需使用浏览、搜索等基本服务，您不需要注册成为我们的用户及提供上述信息。
</p>
                <p>
                    【改善功能】：通过您的同意，我们可能从第三方获取您授权共享的【账户信息（头像、昵称）】，并在您同意本隐私政策及同意后，将您的第三方账户与您在太保妙健康的账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会依据与第三方的约定，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
</p>
                <p>
                    2、注册成为推广会员
</p>
                <p>
                    【基本功能】：您注册太妙福利联盟成为推广会员，您需要根据服务规则向我们提供【真实姓名和有效身份证件（包括但不限于身份证、医保卡、护照）的号码和图片、银行卡、手机号】。
</p>
                <p>
                    【改善功能】：通过您的同意，我们可能从第三方获取您授权共享的【账户信息（头像、昵称）】，并在您同意本隐私政策及同意后，将您的第三方账户与您在太保妙健康的账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会依据与第三方的约定，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
</p>
                <p>
                    3、信息浏览、收藏、关注与分享
</p>
                <p>
                    【基本功能】：在您使用信息浏览、收藏、关注与分享等功能时，我们需要收集与使用【搜索查询内容、浏览器的类型、电信运营商、使用的语言、访问日期和时间、分享的地址与内容及您访问的网页记录】，否则我们没办法提供信息浏览、收藏、关注与分享等功能。以便于我们进行实名认证。如果您不提供这些信息，可能会影响您提供产品/服务推广服务、获取佣金。
</p>
                <p>
                    【改善功能】为向您提供更契合您的页面展示和搜索结果、了解产品适配性、识别帐号异常状态，预防交易和资金风险，最终保障向您提供安全稳定的服务，我们会取得您授权后收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：a)设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息）;b)为改善我们的产品或服务、向您提供更符合您个性化需求的信息展示、搜索及推送服务，我们会根据您的浏览及搜索记录、设备信息、位置信息等，提取您的浏览、搜索偏好、行为习惯、位置信息等特征，基于特征标签进行间接人群画像并展示、推送信息。
</p>
                <p>
                    4、下单
</p>
                <p>
                    【基本功能】：当您在太保妙健康订购产品/服务时，我们可能会收集您的交易信息、记录，包括【您所购买的产品/或服务信息、具体订单号、订单创建时间、您应支付的金额，我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息】。其中，如您购买产品/服务，在下单过程中,您需通过主动填写的方式至少提供【您的收货人姓名、收货地址、收货人联系电话】；您可以通过太保妙健康为其他人订购产品/服务，但需提供该实际收货人的前述个人信息，且需确保您已经取得其授权同意。
</p>
                <p>
                    5、支付
</p>
                <p>
                    【基本功能】：当您进行结算时，我们或我们合作方的系统会生成您购买该商品的订单。在您下单后，您可以选择我们合作的第三方支付机构（以下称“支付机构”）所提供的支付服务（您可以通过点击选择、使用第三方服务）。我们或我们合作的第三方需要将您的【订单号与交易金额相关信息】与这些支付机构共享以实现其确认您的支付指令并完成支付。
</p>
                <p>
                    6、交付
</p>
                <p>
                    【基本功能】：若您所选择的产品或服务需要配送，我们或我们合作的第三方需要您提供【姓名、地址、电话号码】等订单信息，当您下单并选择货到付款或在线完成支付后，您知晓并同意我们合作的第三方会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。为了使我们及时获悉并确认交付进度及状态，向您提供售后服务，您同意我们可从第三方服务主体收集与交付相关的信息。
</p>
                <p>
                    【改善功能】：我们可能会收集您的【服务日志信息和设备信息，包括：订单信息、浏览信息、交易及售后信息、访问日期及时间等】，为了改进我们的产品与/或服务，以及给您提供更好的用户体验，我们会根据您的【服务日志信息和设备信息】提取您的偏好特征，基于特征标签产出间接人群画像，用于我们的用户数据库维护和改进我们的业务功能，并开展新的业务功能。
</p>
                <p>
                    7、客服与售后服务
</p>
                <p>
                    【基本功能】：当您使用客服与售后服务时，我们及我们的合作方会使用您的【账号信息和订单信息：该订单信息会载明您所购买的商品及/或服务信息、具体订单号、订单创建时间、您应支付的金额】我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客服与售后服务及其他我们明确告知的目的。
</p>
                <p>
                    【改善功能】：为了提供服务及改进服务质量的合理需求，我们还可能使用您的其他信息，包括您与客服联系时提供的相关信息，您参与调查问卷、参加健康运动等活动时提供的信息。
</p>
                <p>
                    8、为您提供评论、问答等信息公开发布功能
</p>
                <p>
                    您可通过我们为您提供的评论、问答、论坛、晒单分享及其他信息发布功能公开发布信息，包括发表评论、问答内容、以及作为卖家发布商品及或服务相关信息。
</p>
                <p>
                    请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至敏感信息。请您更加审慎地考虑是否共享或公开分享相关信息。若你能公开发布的信息中涉及儿童个人信息，您需在发布前征求其监护人的同意。
</p>
                <p>
                    9、健康管理类服务
</p>
                <p>
                    【基本功能】：当您使用我们的各类健康管理类服务【包括健康互动任务、太妙法宝、健康管家培训、视频医生、原研药优惠、居家防疫、监测报告、自测用药、健康关爱服务】时，请您务必认真阅读本政策、《注册协议》、平台规则以及产品/服务页面，了解您接受该服务的过程中，可能需要提供的个人信息。每项具体服务所需您授权的信息为【（1）健康互动任务：openid、本人手机号、微信昵称、头像、地区及性别；（2）太妙法宝：姓名、身份证号、所在公司、工号；（3）健康管家培训：手机号、所在位置、耳机、麦克风；（4）视频医生：openid、身份证号、使用视频医生的时长、所咨询问题的类型等；（5）原研药优惠：openid、微信昵称、手机号；（6）居家防疫：openid、手机号；（7）监测报告：姓名、身份证号、手机号、健康监测结果;（8）自测用药：openid、本人手机号、微信昵称、微信头像、所在位置、性别；（9）健康关爱服务：姓名、身份证号、手机号】。
</p>
                <p>
                    此外，当您使用与我们的产品与／或服务连接的智能设备时，通过您的授权，我们可能会收集有关智能设备的基本信息，包括【设备名称、设备ID】等。根据您选择与我们的产品与／或服务连接的不同智能设备，我们可能会收集您的智能设备产生的【健康数据、运动数据、图片、语音、位置等】，并可能需要我们将上述信息提供给为您实际提供服务的具体服务提供者（如第三方合作机构），以便该服务提供者直接为您提供服务。
</p>
                <p>
                    鉴于以上健康管理类服务中部分服务由我们与第三方合作（下称“服务提供方或合作方”）提供，为了使您能够使用健康管理相关服务，我们会在应用程序、网页中嵌入服务提供方的SDK、API或类似应用程序，您同意我们将您的openid或/和手机号等信息共享给服务提供方。如您选择使用视频医生服务、太妙法宝、自测用药等服务，您应审慎阅读相关服务提供方的隐私政策，并作出是否授权的判断，同时您知晓并同意服务提供方可能会在服务环节根据其隐私政策要求您提供手机号、身份证号、就医记录等信息，并使用前述信息，以保证您使用视频医生服务、自测用药等服务。为了使我们及时获悉并确认服务进度及状态，向您提供售后服务，您同意我们可从服务提供方收集与服务相关的信息。我们会对服务提供方获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的数据保护措施，令其按照服务必要性、服务说明、本隐私政策及相关法律法规规定的保密和安全措施来处理个人信息。
</p>
                <p>
                    【改善功能】：我们可能会收集您的【服务日志信息，包括：健康咨询信息、订单信息、浏览信息、交易及售后信息、访问日期及时间等】，为了改进我们的产品与/或服务，以及给您提供更好的用户体验，我们会根据您的【服务日志信息】优化服务模型、产品，以便为您提供更好的产品、服务。
</p>
                <p>
                    10、为您提供安全保障
</p>
                <p>
                    【基本功能】：为提高您使用我们的产品与/或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的【浏览信息、订单信息、设备信息】等手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接。
</p>
                <p>
                    11、设备权限调用
</p>
                <p>
                    【基本功能】：为向您提供便捷、优质的服务，我们可能会根据您的授权调用您设备的一些权限，以下是我们可能调用的设备权限列表及对应的使用目的说明，您有权随时选择关闭下列权限的授权，但可能会影响您正常使用太保妙健康服务或产品的部分或全部功能。
</p>
                <p>
                    （1）相机/摄像头
</p>
                <p>
                    a）对应业务功能：视频咨询服务、产品/服务评价、实名认证等
</p>
                <p>
                    b）调用权限目的：使用产品/服务时上传照片、开启摄像头等以便使用相关服务，例如您使用视频医生服务与医生视频沟通；您通过照片识别、寻找商品/服务。
</p>
                <p>
                    c）是否询问：用户使用相应服务前会询问
</p>
                <p>
                    d）可否关闭权限：是
</p>
                <p>
                    （2）通知
</p>
                <p>
                    a）对应业务功能：全部功能
</p>
                <p>
                    b）调用权限目的：接受服务、交付商品、推送服务、协议更新等各类消息。
</p>
                <p>
                    c）是否询问：用户首次注册时询问
 </p>
                <p>
                    d）可否关闭权限：是
 </p>
                <p>
                    e）关闭权限会影响用户什么功能：用户无法接受各类消息，无法使用太保妙健康商品/服务。
 </p>
                <p>
                    （3）存储
 </p>
                <p>
                    a）对应业务功能：全部功能
 </p>
                <p>
                    b）调用权限目的：写入存储用户信息，存储日志等信息
 </p>
                <p>
                    c）是否询问：用户使用相应服务/功能前会询问
 </p>
                <p>
                    d）可否关闭权限：是
 </p>
                <p>
                    e）关闭权限会影响用户什么功能：无法正常使用相关服务
 </p>
                <p>
                    （4）位置
 </p>
                <p>
                    a）对应业务功能：向您推荐产品/服务、识别您的身份等
 </p>
                <p>
                    b）调用权限目的：根据您所在位置为您推荐更好的产品/服务，识别您的身份
 </p>
                <p>
                    c）是否询问：用户使用相应服务/功能前会询问
 </p>
                <p>
                    d）可否关闭权限：是
 </p>
                <p>
                    e）关闭权限会影响用户什么功能：无法正常使用相关服务
 </p>
                <p>
                    （5）麦克风
 </p>
                <p>
                    a）对应业务功能：全部功能
 </p>
                <p>
                    b）调用权限目的：使用产品/服务时，例如您使用视频医生服务与医生视频沟通。
 </p>
                <p>
                    c）是否询问：用户首次使用相关功能时会弹窗询问。
 </p>
                <p>
                    d）可否关闭权限：是
 </p>
                <p>
                    e）关闭权限会影响用户什么功能：无法正常使用相关服务。
 </p>
                <p>
                    （6）通讯录信息
 </p>
                <p>
                    a）对应业务功能：全部功能
 </p>
                <p>
                    b）调用权限目的：使用产品/服务时，例如我们将在您开启通讯录权限后收集您的通讯录信息，由您为他人开通服务权益等；我们将在您开启通讯录权限后收集您的通讯录信息使您在购物时可以更便利地取用您通讯录内的联系人信息，无需再手动输入；此外，为提升您在使用我们产品及/或服务过程中的社交互动分享乐趣，与您认识的人分享购物体验，在经您同意的前提下，我们也可能对您联系人的姓名和电话号码进行加密收集，帮助您判断您的通讯录联系人是否同为我们的用户进而为你们的交流建立联系，同时，用户可选择开启或关闭好友隐私权限决定自己是否可被其他用户通过手机号码搜索、联系。
 </p>
                <p>
                    c）是否询问：用户首次使用相关功能时会弹窗询问。
 </p>
                <p>
                    d）可否关闭权限：是
 </p>
                <p>
                    e）关闭权限会影响用户什么功能：无法正常使用相关服务。
 </p>
                <p>
                    若您提供的信息中含有其他用户的个人信息，在向我们提供这些信息前，您应确保已取得信息主体的合法授权。若其中涉及儿童个人信息的，您需在发布前取得其监护人的同意，且监护人有权通过本政策相关约定要求我们更正或删除儿童个人信息。当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
 </p>
                <p>
                    （三）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：
 </p>
                <p>
                    1、与国家安全、国防安全有关的；
 </p>
                <p>
                    2、与公共安全、公共卫生、重大公共利益有关的；
 </p>
                <p>
                    3、与犯罪侦查、起诉、审判和判决执行等有关的；
 </p>
                <p>
                    4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
 </p>
                <p>
                    5、所收集的个人信息是个人信息主体自行向社会公众公开的；
 </p>
                <p>
                    6、从合法公开披露的信息中收集的您的个人信息的；
 </p>
                <p>
                    7、根据您的要求签订合同所必需的；
 </p>
                <p>
                    8、用于维护所提供的产品与/或服务的安全稳定运行所必需的；
 </p>
                <p>
                    9、为合法的新闻报道所必需的；
 </p>
                <p>
                    10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
 </p>
                <p>
                    11、法律法规规定的其他情形。
 </p>
                <p>
                    （四）您同意，我们使用以下处理后的数据无需另外取得您的同意：
 </p>
                <p>
                    1、根据适用的法律，若我们采取技术措施和其他必要措施对个人信息进行处理，使得数据接收方无法重新识别特定个人且不能复原；
 </p>
                <p>
                    2、对收集的信息进行去标识化地研究、统计分析和预测。
 </p>
                <p>
                    二、我们如何使用Cookie和同类技术
 </p>
                <p>
                    Cookie 是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie 的内容只能由创建它的服务器检索或读取。每个 Cookie 对您的网络浏览器或移动应用程序都是唯一的。Cookie 通常包含标识符、站点名称以及一些号码和字符。
 </p>
                <p>
                    为确保太保妙健康的正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，太保妙健康能够记住您的选择，提供个性化增强服务等。您可根据自己的偏好管理或删除Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问太保妙健康时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
 </p>
                <p>
                    我们网站使用的 Cookie，已按照 ICC UK Cookie 指南中的准则进行了分类。我们在自己的网站和其他在线服务中使用了如下类别的 Cookie：
 </p>
                <p>
                    第 1 类 — 绝对必需的 Cookie
 </p>
                <p>
                    这类 Cookie 是必不可缺的，它们能让你尽情浏览我们的网页并使用其功能。缺少这类 cookie，记录登录状态等服务就无法提供。
 </p>
                <p>
                    第 2 类 — 性能 Cookie
 </p>
                <p>
                    这类 Cookie 收集你使用网站方式的信息，比如你最常访问的页面。此数据可用来帮我们优化网站，使它们更易于浏览。这类 Cookie 也用于让我们的网站数据加载更快，更高效的展示您想预览的内容。这些 Cookie 不会收集身份识别信息。这些 Cookie 收集到的均为汇总信息，也是匿名信息。
 </p>
                <p>
                    第 3 类 — 功能性 Cookie
 </p>
                <p>
                    这类 Cookie 可让网站记住你在浏览时所做的选择。比如，我们可能在 Cookie 中储存你的地理位置，确保为你显示针对你所在的地区本地化的网站。我们还可能记录偏好设置信息，如字号、字体和其他可自定义的网站元素。它们还可用于跟踪已经看过的特色产品或视频，避免重复记录。这些 Cookie 收集的信息不会识别你的个人身份，也无法跟踪你在非太保妙健康网站上的浏览活动。
 </p>
                <p>
                    三、我们如何共享、转让、公开披露您的个人信息
 </p>
                <p>
                    （一）共享
 </p>
                <p>
                    您理解并同意，我们不会与其他任何公司、组织和个人共享您的个人信息，但以下情况除外：
 </p>
                <p>
                    1、事先获得您明确的同意或授权；
 </p>
                <p>
                    2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
 </p>
                <p>
                    3、为顺利为您提供服务，您同意我们向服务提供方、关联方共享您的订单信息、账户信息、设备信息、运动数据、以及位置信息等。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的服务提供方无权将共享的个人信息用于任何其他用途。

                    服务提供方的类型及共享的信息包括以下：
 </p>
                <p>
                    （1）商品或技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方以及服务提供方。这些支持和服务包括为我们供货或提供基础设施技术服务、服务提供方、物流配送服务、支付服务、运营服务等。我们共享这些信息的目的是为了使您能够使用相应服务、可以实现我们产品与/或服务的核心购物功能；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付。
 </p>
                <p>
                    （2）委托进行推广的合作伙伴。我们可能会委托第三方向使用我们产品与/或服务的用户群提供促销推广的服务，从而需要使用去标识化的您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（“委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。
 </p>
                <p>
                    （3）与关联公司间共享：为便于我们基于太保妙健康帐号向您提供服务，推荐您可能感兴趣的信息或保护本政策所定义的关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与关联公司共享。我们只会共享必要的个人信息（如为便于您通过同一帐号使用我们关联公司产品或服务，我们会向关联公司共享您必要的账户信息），如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
 </p>
                <p>
                    （4）第三方商家。若您购买的商品或服务由第三方商家提供，我们可能需要将与交易有关的、您的信息与第三方商家共享，以实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。
 </p>
                <p>
                    此外，对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照本政策来保护、使用您的个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。
 </p>
                <p>
                    4、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护您、我们或我们其他客户的权利及其财产或安全，而与其他公司和组织交换信息。
 </p>
                <p>
                    5、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
 </p>
                <p>
                    （二）转让
 </p>
                <p>
                    我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
 </p>
                <p>
                    1、事先获得您明确的同意或授权；
 </p>
                <p>
                    2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
 </p>
                <p>
                    3、符合与您签署的相关协议或其他的法律文件约定所提供；
 </p>
                <p>
                    4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。
 </p>
                <p>
                    （三）公开披露
 </p>
                <p>
                    我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
 </p>
                <p>
                    1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
 </p>
                <p>
                    2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的数据均在加密密钥的保护之下。
 </p>
                <p>
                    （四）共享、转让、公开披露个人信息时事先征得授权同意的例外
 </p>
                <p>
                    以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
 </p>
                <p>
                    1、与国家安全、国防安全有关的；
 </p>
                <p>
                    2、与公共安全、公共卫生、重大公共利益有关的；
 </p>
                <p>
                    3、与犯罪侦查、起诉、审判和判决执行等司法或行政支付有关的；
 </p>
                <p>
                    4、处于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
 </p>
                <p>
                    5、您自行向社会公开的个人信息；
 </p>
                <p>
                    6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、
 </p>
                <p>
                    四、我们如何保护您的个人信息
 </p>
                <p>
                    （一）我们已采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。我们采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；我们明确了个人信息保护责任部门，针对个人信息收集、使用、共享、委托处理等开展个人信息安全影响评估。同时，我们建立了相关内控制度，对可能接触到您的信息的工作人员采取最小够用授权原则；对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导。
 </p>
                <p>
                    （二）我们仅允许有必要知晓这些信息的员工、公司关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。
 </p>
                <p>
                    （三）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会根据本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
 </p>
                <p>
                    （四）互联网并非绝对安全的环境，而且电子邮件、即时通讯等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。
 </p>
                <p>
                    （五）在通过我们网站与第三方进行网上商品或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
 </p>
                <p>
                    为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
 </p>
                <p>
                    在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。同时，我们将及时将事件相关情况以邮件、电话、推送通知、公告等方式告知您。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
 </p>
                <p>
                    （六）如果您对我们的个人信息保护有任何疑问，可通过本政策约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策约定的联系方式联络我们，以便我们采取相应措施。
 </p>
                <p>
                    五、您如何管理您的个人信息
 </p>
                <p>
                    我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
 </p>
                <p>
                    （一）访问、更正和补充您的信息
 </p>
                <p>
                    您有权随时访问、更正和补充您的个人信息。您可以通过以下方式自行进行：
 </p>
                <p>
                    1、【进入“太保妙健康”并选择联系客服寻求帮助，协助您访问、更正和补充您的信息】；
 </p>
                <p>
                    2、【进入“太保妙健康”并进入“我的”，对您使用商城服务产生的个人资料、售后地址等信息进行访问、更正和补充】。
 </p>
                <p>
                    对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。
 </p>
                <p>
                    1、负责人邮箱为：021-33963797
 </p>
                <p>
                    2、您还可以在工作日9：00-11：30，13：00-17：00期间时拨打我们的客服电话400 880 8660或使用在线客户与我们联系。
 </p>
                <p>
                    3、联系地址：上海市浦东新区世纪大道1239号
 </p>
                <p>
                    （二）一般情况下，我们将在15天回复。
 </p>
                <p>
                    （三）如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报，或诉至上海市浦东新区人民法院。
 </p>


            </div>
        );
    }
}

export default connect()(Privacy);
