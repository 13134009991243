import * as React from "react";
import { connect } from "react-redux";
import "./index.scss";

interface title {
    value: string
}

class ServiceTitle extends React.PureComponent<title, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: props.value
        }
    }
    render() {
        return (
            <div className="title-box">
                <div className="title-line-left">
                </div>
                <div className="title-text">
                    {this.state.value}
                </div>
                <div className="title-line-right">
                </div>
            </div>
        );
    }
}

export default connect()(ServiceTitle);
