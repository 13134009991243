import * as React from "react";
import { connect } from "react-redux";
import iconHealth from "../../assets/empower/icon-health.png";
import iconDoctor from "../../assets/empower/icon-doctor.png";
import iconArrow from "../../assets/empower/icon-arrow.png";
import './empower.scss'
import {Link} from "react-router-dom";
class Empower extends React.PureComponent<{}, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isAgree: false,
        };
    }


    componentDidMount() {
        document.title = '授权';
    }
    render() {
        const {
            isAgree,
        } = this.state;

        return (
            <div>
                <div className="icon-img-bg">
                    <div className="icon-img-box">
                        <div className="icon-img">
                            <img src={iconHealth} className="icon-health" alt=""/>
                        </div>
                        <div className="icon-img">
                            <img src={iconArrow} className="icon-arrow" alt=""/>
                        </div>
                        <div className="icon-img">
                            <img src={iconDoctor} className="icon-doctor" alt=""/>
                        </div>
                    </div>
                    <p className="icon-tip"> 即将跳往科盎健康平台提供的页面后续服务由科盎健康提供 </p>
                </div>
                <p className="agree-title">同意授权以下权限可继续使用服务</p>
                <p className='agree-tip'>- 授权科盎健康使用本人的用户ID、健康体检等相关信息 </p>
                <p className='agree-tip'>- 授权科盎健康向太保妙健康同步本人体检预约、体检服务使用进度等相关数据。</p>
                <div className="exborder-box">
                    <div className="exborder" onClick={this.changeAgree.bind(this)}>
                        <div className={` ${isAgree ? "inborder" : ""}`}></div>
                    </div>
                    <span className="agree">同意</span>
                    <Link to="/Informed">《用户知情确认及授权书》</Link>

                    <button
                        type="button"
                        className={`login-btn ${
                            isAgree ? "active" : ""
                        }`}
                        onClick={this.toLogin.bind(this)}
                    >
                        确认
                    </button>
                </div>



            </div>
        );
    }

    //isAgree
    changeAgree() {
        this.setState({
            isAgree: !this.state.isAgree,
        });
    }
    toLogin(){
        if (this.state.isAgree){
            window.location.href = '/login'
        }

    }
}


export default connect()(Empower);
